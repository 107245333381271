$primary-color: #FF9100;
$red: #FF0000;
$green: #32CD32;
$white: #FFFFFF;
$white-2: #FAFAFA;
$light-gray: #D8D8D8;
// $dark-gray:#D8D8D8;
$light-black: #343A40;
$dark-black: #000000;
$light-blue: #29416D;
$dark-blue: #000945;
$radius : 4px;
$draft: #FF9100;
$listed: #32CD32;
$unlisted: #FF0000;