::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: $primary-color;
}


.banner-title {
    padding-block: 60px 42px;

    @media (max-width:575px) {
        padding-block: 30px 15px;
    }
}

// ************************************Boat Details section****************************
.sticky-bottom {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $white;
}

.banner-image {

    position: fixed;
    height: 100%;
    width: 50%;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: bottom;
    }
}

// .banner-content {
//     overflow: hidden;
//     overflow-y: scroll!important;
//     height: 100% !important;
// }
// .place-layout{
//     overflow-y: auto;
// }

.banner-footer {
    padding: 20px 0;

    @media (max-width:991px) {
        padding: 20px 15px;
    }

    .btn {
        font-weight: 600;
    }

    .back-btn {
        color: $primary-color !important;
        border: none;
        position: relative;

        &::before {
            position: absolute;
            content: "";
            background: url('../icons/arrow_back.svg') no-repeat;
            height: 100%;
            width: 100%;
            left: -7px;
        }
    }
}

// ************************************Add Passenger section****************************
.add-passenger {
    input {
        width: 24px;
        padding: 0;
        border: 0;
        font-weight: 600;
        font-size: 16px;
    }

    p {
        font-size: 18px;
        font-weight: 600;
    }
}

// ************************************Place Location section****************************
.location {
    box-shadow: 0px 4px 8px #00000014;
    border: 1px solid $light-gray;
    padding: 24px;
}

.specific-location {
    .form-check-input {
        padding: 10px 0;
    }

    a {
        color: $light-black;
    }
}

// ************************************add Photos section****************************
.upload-image {
    min-height: 176px;
    border: 2px dashed #343A404D;
    position: relative;
    border-radius: 8px;

    .form-control {
        /* min-height: 176px; */
        border: 1px solid red;
        position: absolute;
        top: 67px;
        left: 0;
        right: 0;
        bottom: 0;
        height: 40px;
        width: 140px;
        z-index: 1;
        margin: auto;
    }

    label {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: fit-content;
        height: fit-content;
    }

    input {
        opacity: 0;
    }
}

.uploaded-image {
    min-height: 176px;
    max-height: 176px;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid rgb(241, 241, 241);
    border-radius: 4px;
    transition: 0.5s;

    img {
        height: 100%;
        width: 100%;
        min-height: 176px;

    }

    .cover-image {
        width: fit-content;
        height: fit-content;
        background: #00000099;
        border-radius: 2px;
        font-size: 12px;
        color: $white;
        padding: 2px 5px;
        transition: 0.5s;
    }
}

.modify-image {
    width: 100%;
    right: 0px;
    padding-inline: 10px;
    top: 10px;
}

.edit {
    height: 30px;
    width: 30px;
    background: $white;
    border: 1px solid $light-gray;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
        height: 30px;
        width: 30px;
    }
}



// ************************************add Photos section****************************

.text-area {
    min-height: 120px !important;
    width: 100%;
    border: 1px solid $light-gray;
    outline: none;
    padding: 10px;
    border-radius: 4px;

    &:hover {
        border-color: $primary-color;
    }

    &:focus {
        border-color: $primary-color;
    }
}

// ************************************Morning Panormic section****************************
.morning-banner {
    height: 400px;
    max-height: 400px;
    overflow: hidden;
    border-radius: 10px 0 0 10px;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    @media (max-width:991px) {
        height: 400px;
    }

    @media (max-width:767px) {
        border-radius: 10px 10px 0 0;
        height: 210px;
    }
}

.boat-image-list {
    height: 196px;
    max-height: 100%;
    overflow: hidden;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    @media (max-width:767px) {
        border-radius: 10px;
        height: 150px;
    }
}

.boat-group-image {

    &:nth-child(1) {
        border-radius: 0px 10px 0px 0px;
    }

    @media (max-width:767px) {
        border-radius: 0px 10px 0px 0px;
    }
}

.boat-group-image-last {

    &:nth-child(1) {
        border-radius: 0px 0px 10px 0px;
    }
}

.boat-calender {
    border-right: 1px solid $light-gray;
    height: 100vh;
    overflow-y: auto;

    @media (max-width:991px) {
        border-right: none;
    }
}

.price-card {
    box-shadow: 0px 4px 14px #0000001A;
    border-radius: 10px;

    input {
        margin-top: 19px;
    }

    .select-date {
        position: relative;

        ::placeholder {
            padding: 0;
            font-weight: 600;
        }

        &::before {
            position: absolute;
            content: 'Date';
            top: 25px !important;
            bottom: 0 !important;
            left: 20px !important;
            margin: auto;
            font-weight: 700;
            text-transform: uppercase;
            z-index: 1;
            height: 100% !important;
            width: 20px !important;
        }
    }

    .ant-picker-suffix {
        position: relative;

        &::after {
            position: absolute;
            content: '';
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            background: url('../icons/detail_dropdown.svg') no-repeat center;
            height: 20px;
            width: 20px;
        }

        svg {
            opacity: 0;
        }
    }
}

.ant-picker {
    box-shadow: none !important;
    border-radius: $radius;
    border: 1px solid $light-gray;
    padding: 13px 20px;
    width: 100%;
}

.morning-content {
    ul {
        li {
            padding-block: 7px;
        }
    }

    .map {
        border-radius: 6px;
    }

    .location-icon {
        width: fit-content;
    }

    .location-text {
        width: fit-content;
        height: fit-content;
        background: $white;
        padding: 14px 26px;
        border-radius: $radius;
    }

    p {
        font-size: 15px;
        font-weight: 600;
    }
}

.location {
    cursor: pointer;
}

// ************************************Boat Listing section****************************

.status-dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: $primary-color;
}

.table {
    p {
        @media (max-width:575px) {
            font-size: 14px;
        }
    }

    th,
    td {
        padding: 15px;
        vertical-align: middle;
        white-space: nowrap;
        font-family: 'Nunito', sans-serif !important;
        color: $light-black;

        &::first-letter {
            text-transform: capitalize;
        }
    }

    th {
        font-size: 12px;
        font-weight: bold;
    }

    td {
        font-size: 16px;

        @media (max-width:575px) {
            font-size: 14px;
        }
    }

    .thead {
        background: #F8F8F8;
    }
}

.boat-image {
    height: 50px;
    width: 80px;
    overflow: hidden;
    border-radius: $radius;

    @media (max-width:575px) {
        height: 40px;
        width: 60px;
    }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}


.ant-tabs {
    font-family: 'Nunito', sans-serif !important;

    .ant-tabs-tab {
        font-size: 18px;
        padding-inline: 10px;

        &:hover {
            color: $light-black;
        }

        &:focus {
            color: $light-black;
        }

        &:active {
            color: $light-black;
        }
    }
}

.ant-tabs {
    .ant-tabs-tab.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
            color: $dark-black;
            border-color: $dark-black;
        }
    }
}
.active{
    color: $primary-color !important;
}


.ant-tabs {
    .ant-tabs-ink-bar {
        background: $dark-black;
    }
}

// ************************************Profile section****************************

.user-profile {
    border: 1px solid $light-gray;
    padding-block: 41px;

    @media (max-width:575px) {
        padding-block: 20px;
    }

    .profile-image {
        height: 120px;
        width: 120px;
        border-radius: 50%;
        overflow: hidden;
        margin-bottom: 24px;

        @media (max-width:575px) {
            margin-bottom: 15px;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}

.name {
    border: 1px solid $light-gray;
    border-radius: $radius;
    padding: 20px 24px;
}


// ************************************Morning panormic lisiting section****************************
.morning-panormic-listing {
    background: $white-2;
 .Pricing{
    padding: 40px;
    @media (max-width:1199px) {
       padding: 20px;
    }
 }
    .tab-box {
        height: 100%;

        .tab-nav {
            min-width: 300px;
            max-height: 700px;
            width: 300px;
            padding: 20px 20px;
            min-height: 622px;
            position: sticky !important;
            top: 100px !important;
            @media (max-width:1199px) {
                min-width: 220px;
                width: 220px; 
                padding: 0px;
                min-height: fit-content;
            }
            @media (max-width:991px) {
                min-width: 200px;
                width: 200px; 
                padding: 0px;
                min-height: fit-content;
            }
            @media (max-width:767px) {
                min-width: 200px;
                width: 100%; 
                padding: 0px;
                min-height: fit-content;
            }
        }
    }

    .nav-link {
        background: transparent;
        color: $light-black !important;
        font-weight: bold;
        padding: 0;
        opacity: 0.8;
    }

    .nav-pills .nav-link.active {
        background: transparent;
        color: $light-black !important;
        opacity: 1;
    }

    .accordion-item {
        border: none;
    }

    .accordion-button {
        background: transparent;
        color: $light-black !important;
        box-shadow: none !important;
        font-weight: bold;
    }

    .accordion-button::after {
        display: none;
    }

    .accordion-body {
        li {
            border-left: 1px solid $light-gray;
        }

        .nav-link {
            font-weight: normal;
            font-size: 14px;
            padding: 10px 12px;
            border-radius: 0;
            color: $light-black;
            opacity: 1;
            border-left: 2px solid transparent;
            outline: none;
            font-weight: 600;
        }
    }
}

.active-tab {
    border-left: 2px solid $primary-color !important;

}

.amenities-list {
    li {
        padding-block: 7px;
    }
}

.Pricing {
    padding: 40px;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.list-btn {
    .btn {
        border-radius: 10px;
    }
}

.profile-btn {
    position: relative;

    input {
        opacity: 0;
        width: 30%;
        height: 38px;
        cursor: pointer;

    }

    .btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        cursor: pointer;
    }
}

// ************************************Bugs Css****************************
.ant-select-item-option-content {
    font-family: 'Nunito', sans-serif !important;
}

.ant-form-item {
    margin: 0;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    font-size: 16px;
    color: #707579;
    font-weight: 500;
}


.ant-dropdown-arrow {
    display: none !important;
}

.ant-dropdown {
    box-shadow: 0px 0px 10px #00000014 !important;
    border-radius: 8px;
    font-family: 'Nunito', sans-serif !important;
}

.ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft {
    padding: 0;
}

.ant-dropdown .ant-dropdown-menu {
    padding: 10px;
}

.ant-form label {
    font-size: 16px;
    color: $light-black;
    font-weight: normal;
    font-family: 'Nunito', sans-serif;
}

.boat-listing-header {
    .search-icon {
        position: absolute;
        top: 50%;
        background: white;
        left: 5px;
        transform: translate(50%, -50%);
        margin: auto;
        z-index: 10;
    }

}

.search-input {
    width: 100%;

    .form-control {
        width: 25%;
        transition: 0.3s;

        &:focus {
            width: 90%;
        }
    }
}

.photo-slider {
    height: 100px;
    border-radius: 4px;
    margin-inline: 5px;
    overflow: hidden;
    position: relative;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.slider-box {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 20%;
        width: 0px;
        background: transparent;
        box-shadow: 0px 0px 30px 70px rgb(255, 255, 255);
    }
}

// .ant-float-btn-default
.ant-float-btn-default {
    z-index: 1111;
}

.ant-spin-nested-loading {
    height: 100%;
    font-family: 'Nunito', sans-serif !important;
}

.ant-spin-container {
    height: 100%;
    font-family: 'Nunito', sans-serif !important;
}

.ant-badge-status-text {
    text-transform: capitalize !important;
    font-weight: 600;
    font-size: 16px;
}


// bugs
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 100%;
    margin-left: 7px;
}

.ant-pagination-options {
    display: none !important;
}

.ant-select-arrow {
    color: $dark-black !important;

    svg {
        font-size: 14px !important;
    }
}

.ant-dropdown-menu-title-content {
    font-weight: bold;
    color: $dark-black;
}

// .inquiry-section {
// .ant-select-item-option-selected {
//     position: relative;
//     background: $white !important;

//     &::after {
//         position: absolute;
//         content: '';
//         background: url('../icons/dropdown_tick.svg') right no-repeat;
//         height: 20px;
//         width: 120px;
//     }
// }

.ant-modal-footer {
    display: none;
}

// show-more-image
.show-more-image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0px;

    .btn {
        color: $white;
        font-size: 35px;
        font-weight: 600;
        letter-spacing: 2px;
        height: 100%;
        width: 100%;
        position: absolute;
        background: linear-gradient(rgba(0, 0, 0, 0.404), rgba(0, 0, 0, 0.358));

        &:active {
            color: $white;
        }

        &:focus {
            color: $white;
        }
    }
}

.ant-radio-button-wrapper {
    height: 100% !important;

    span {
        vertical-align: text-top;
    }
}

.ant-picker-calendar .ant-picker-calendar-header {
    padding: 25px 20px !important;
}

.ant-radio-group .ant-radio-button-wrapper {
    border-radius: 5px;
}

.ant-radio-group .ant-radio-button-wrapper:last-child {
    display: none;
}

.price-input {
    .form-control {
        padding: 7px 7px;
    }
}

.contact-image{
    height: 500px;
    overflow: hidden;
    border-radius: 16px;
    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.ant-input-group .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: inherit;
    border: 1px solid transparent !important;
    box-shadow: none;
}

.social-image{
    height: 350px;
    border-radius: 6px;
    overflow: hidden;
    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

// shareBoat
.share-boat-image{
    height: 70px;
    width: 75px;
    border-radius: 5px;
    overflow: hidden;
    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}
.boat-sharing{
    .btn{
     border: 1px solid $light-gray;
     font-weight: 600;
     border-radius: 15px;
     &:hover{
        background: rgb(246, 246, 246);
     }
    }
}