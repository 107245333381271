h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
    margin: 0;
    color: $dark-black;


    &::first-letter {
        text-transform: capitalize;
    }
}

h2 {
    font-size: 30px !important;
   

    @media (max-width:575px) {
        font-size: 25px;
    }
}

h3 {
    font-size: 26px;
   
    @media (max-width:575px) {
        font-size: 22px;
    }
}

h4 {
    font-size: 22px;

    @media (max-width:575px) {
        font-size: 18px;
    }
}

h5 {
    font-size: 20px;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.btn {
    font-size: 16px;
    font-weight: normal;
    font-family: 'Nunito', sans-serif !important;
    cursor: pointer !important;
    padding: 6px 20px;

    &::first-letter {
        text-transform: capitalize;
    }

    @media (max-width:575px) {
        font-size: 14px;
    }
}

p {
    margin: 0;
    font-size: 16px;
    color: $light-black;

    &::first-letter {
        text-transform: capitalize;
    }
}
// .inquiry-name{
//     p{
//         // &::first-letter {
//         //     text-transform: capitalize;
//         // }
//     }
// }
.rounded {
    border-radius: $radius !important;
}

.rounded-2 {
    border-radius: 2px !important;
}

.bg-red {
    background: $red !important;
}

.text-red {
    color: $red !important;
}

.bg-yellow {
    background: $primary-color !important;
}

.bg-white {
    background: $white !important;
}

.bg-green {
    background: $green !important;
}

.bg-draft {
    background: $draft !important;
}

.bg-listed {
    background: $listed !important;
}

.bg-unlisted {
    background: $unlisted !important;
}

.text-yellow {
    color: $primary-color !important;
}
.text-dark-black{
    color: $dark-black !important;
}
.text-black {
    color: $light-black !important;
}
.fw-600{
    font-weight: 600 !important;
}
.fw-500{
    font-weight: 500 !important;
}

label {
    color: $light-black;
    font-size: 16px;
    font-weight: normal;

    &::first-letter {
        text-transform: capitalize;
    }
}

.btn-yellow {
    background: $primary-color !important;
    color: $white !important;
    border: 0;
    border-radius: 4px;
}

.btn-outline-yellow {
    background: $white !important;
    color: $primary-color !important;
    border: 1px solid $primary-color !important;
    border-radius: 4px;
}
.btn-outline-red {
    background: $white !important;
    color: $red !important;
    border: 1px solid $red !important;
    border-radius: 4px;
}

::placeholder {
    font-size: 16px;
    color: $light-black;
    font-weight: normal;
    padding-inline: 7px;

    &::first-letter {
        text-transform: capitalize;
    }
}

.form-control {
    box-shadow: none !important;
    border-radius: $radius;
    border: 1px solid $light-gray;
    padding: 7px 20px;

    &:hover {
        border-color: $primary-color;
    }

    &:focus {
        border-color: $primary-color;
    }

    @media (max-width:575px) {
        padding: 10px 15px;
    }
}


.nav-link {
    font-weight: 600;

    &:hover {
        color: $primary-color;
    }
}

.form-check-input {
    box-shadow: none !important;
    border-radius: 2px !important;

    &:checked {
        background-color: $primary-color;
        border-color: $primary-color;
    }

    &:focus {
        // background-color: $primary-color;
        border-color: $primary-color;
    }
}

.form-check-radio {
    box-shadow: none !important;
    border-radius: 50% !important;
}

.form-check-toggle {
    box-shadow: none !important;
    border-radius: 25px !important;
    height: 25px;
    width: 41px !important;
}


.ant-input {
    box-shadow: none !important;
    border-radius: $radius;
    border: 1px solid $light-gray;
    padding: 8px 20px;
    font-family: 'Nunito', sans-serif !important;
    font-size: 16px;
}

.ant-input:hover {
    border-color: $primary-color !important;
}

.ant-input-affix-wrapper {
    box-shadow: none !important;
    border-radius: $radius;
    border: 1px solid $light-gray;
    padding: 11px 20px;
}

.ant-input-affix-wrapper:hover {
    border-color: $primary-color !important;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
    font-family: 'Nunito', sans-serif !important;

    li {
        font-size: 16px;

        ::first-letter {
            text-transform: capitalize;
        }
    }
}

.ant-select {
    font-weight: 600;
    .ant-select-selector {
        box-shadow: none !important;
        border-radius: $radius !important;
        border: 1px solid $light-gray !important;
        padding: 2px 20px !important;
        height: auto !important;
        font-family: 'Nunito', sans-serif;
        font-size: 16px;
        color: $light-black !important;

        &:hover {
            border-color: $primary-color !important;
        }

        &:active {
            border-color: $primary-color !important;
        }

        @media (max-width:575px) {
            padding: 7px 15px !important;
        }
    }

}


.form-switch .form-check-input:focus {
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e');
}

.ant-pagination {
    .ant-pagination-item {
        margin-inline-end: 5px !important;
    }
}

span::first-letter {
    text-transform: uppercase;
}

.ant-select-item{
    font-weight: 600 !important;
}