@import "assets/css/theme.scss";
@import "assets/css/layout.scss";
@import "assets/css/typography.scss";
@import "assets/css/style.scss";
@import 'react-quill/dist/quill.snow.css';

// @font-face {
//     font-family: 'Nunito', sans-serif;
//     src: url('./assets/fonts/Nunito/static/Nunito-Regular.ttf');
//     font-weight: normal;
// }

// @font-face {
//     font-family: 'Nunito', sans-serif;
//     src: url('./assets/fonts/Nunito/static/Nunito-SemiBold.ttf');
//     font-weight: medium;
// }
// @font-face {
//     font-family: 'Nunito', sans-serif;
//     src: url('./assets/fonts/Nunito/static/Nunito-Bold.ttf');
//     font-weight: bold;
// }
body{
    font-family: 'Nunito', sans-serif !important;
    height: 100%;
}

.morning-panormic-listing,
.Location,
.Pricing,
.listing-content{
    font-family: 'Nunito', sans-serif !important;
}
html{
    height: 100%;
}


div#root {
    height: 100%;
}


.line-through-danger{
    color: #E53C3C;
    text-decoration: line-through;
    text-decoration-thickness: 2px;
}

.inquiry-email{
    text-transform: lowercase !important;
    font-size: 16px;
}