// **************************Header***********************************
header {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 4px #00000014;
    padding-block: 16px;
    z-index: 999 !important;

    .btn {
        padding: 10px 20px;
        border-radius: 25px;
    }

    .btn-outline-dark {
        background: #343A400A;
        border: 1px solid #343A40;
        border-radius: 50px;
    }
}

.custom-container{
    @media all and (min-width:1441px){
        max-width: 91.67%;

    }

}
.profile-image{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    overflow: hidden;
    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

// ******************************************** Auth CSS *****************************************
.signin-form {
    min-height: 100vh;
    overflow: hidden;

    h3 {
        margin-bottom: 32px;
    }

    .login-btn {
        margin-top: 30px;

        .btn {
            padding: 12px;
            letter-spacing: 0.16px;
            font-weight: bold;
        }
    }
}

.signin-banner {
    max-height: 100vh;
    overflow: hidden;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.footer{
    background: $dark-blue;
   .container{
    padding-block: 32px 38px;
   }
    h5{
        color: $white;
    }
    li{
        padding-block: 10px;
    }
    .nav-link{
        color: $white;
        display: flex;
        align-items: center;
        gap: 20px;
        font-size: 14px;
    }
}
.copyright{
    border-top: 1px solid #4A4F70;
    padding-block: 20px;
}

// boat-main-layout
.boat-main-layout{
    height: 100%;
    // overflow: hidden;
}

.navbar-toggler:focus {
    box-shadow: none;
}